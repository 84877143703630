import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import PrivateRoutes from "utils/PrivateRoutes";
const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route element={<PrivateRoutes />}>

        <Route path="admin/*" element={<AdminLayout />} />
      </Route>
      <Route path="/" element={<Navigate to="/auth" replace={true} />} />
    </Routes>
  );
};

export default App;
