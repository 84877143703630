import React, {useState,} from "react";
import { Navigate } from "react-router-dom";
import axios from 'axios'

export default function SignIn() {

  const disabled = false
  const state = ''
  const [auth,setAuth] = useState(false)

  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')

  const handleUsername = (e)=>{
    setUsername( e.target.value)
  }

  const handlePassword = (e)=>{
    setPassword( e.target.value)
  }

  let data= new FormData()
  data.append('username',username)
  data.append('password',password)

  const options = {
    method: 'POST',
    maxBodyLength: Infinity,
    url:'https://investors.izzatcouncil.com/api/auth/login',
    
    data : data
  };

  const handleSubmit = async () =>{

    try{
      const response = await axios.request(options)
      const res = response.data

      // Saving to local storage 

      if(res.message === 'Login Sucess'){
        console.log('Login Success response')
        setAuth(true)
        localStorage.setItem('accessToken',res.token.acess_token)
        localStorage.setItem('refreshToken',res.token.refresh_token)
        localStorage.setItem('message',res.message)

      }
      else{
        console.log('login access error')
      }
  
    }

    catch(error) {
      console.error('An error occurred:', error);
    };

    
  }


  return (
    <div className="mt-16 gap-3 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your username and password to sign in!
        </p>
        
        {/* Email */}

        <label
        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
      >
        Username*
      </label>
        <input
        type= 'text'
        onChange={handleUsername}
        placeholder='Username'
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      />

        {/* Password */}
        <label
        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
      >
        Password*
      </label>
        <input
        type= 'password'
        onChange={handlePassword}
        placeholder='Password'
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      />
        
        <button  
        onClick={handleSubmit}
        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
        
      </div>
      {
        auth ? 
        <Navigate to='/admin' replace={true} /> : ''
      }
    </div>
  );
}
