import { useEffect, useState } from "react";

import axios from "axios";

import TotalSpent from "views/admin/default/components/TotalSpent";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
// import tableDataCheck from "./variables/tableDataCheck.json";

const Dashboard = () => {

  const loading = 'loading ...';  
  const [totalMonthlySales,setTotalMonthlySales] = useState('')
  const [recentOrdersState, setRecentOrdersState] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [isMonthlyLoading, setIsMonthlyLoading] = useState(true);
  const [totalSalesState,setTotalSalesState] = useState('')
  const [totalOrderState,setTotalOrderState] = useState('')
  const [totalUsersState,setTotalUsersState] = useState('')

  const [totalWeeklySalesState,setTotalWeeklySalesState] = useState('')
  const [totalWeeklySalesNumber,setTotalWeeklySalesNumber] = useState('')

  const [totalWeekTwo, setTotalWeekTwo] = useState('')
  const [totalWeekTwoNumber, setTotalWeekTwoNumber] = useState('')

  const [totalWeekThree, setTotalWeekThree] = useState('')
  const [totalWeekThreeNumber, setTotalWeekThreeNumber] = useState('')

  const [totalWeekFour, setTotalWeekFour] = useState('')
  const [totalWeekFourNumber, setTotalWeekFourNumber] = useState('')

  const [totalWeekFive, setTotalWeekFive] = useState('')
  const [totalWeekFiveNumber, setTotalWeekFiveNumber] = useState('')

  const [totalDailySalesState,setTotalDailySalesState] = useState('')

  let data = new FormData();
  
  
  const accessToken = localStorage.getItem('accessToken')

  const options = {
    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_monthly_sales',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };

  const recentOrderOptions = {

    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_recent_orders',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };

  const getAllSalesOptions = {

    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_overall_sales',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };

  const completedOrderOptions = {

    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_order_status',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };

  const totalUsersOptions = {

    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_user_count',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };

  const totalWeeklyOptions = {

    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_weekly_sales',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };
  
  const totalDailyOptions = {

    method: 'POST',
    url : 'https://investors.izzatcouncil.com/api/admin/get_daily_sales',
    headers:{
      'Authorization' : `Bearer ${accessToken}`,

    },
    data : data
  };
  

    useEffect( () => {

      async function monthlySales (){
        try {
          const response = await axios.request(options)
          setTotalMonthlySales(response.data)
          setIsMonthlyLoading(false);

        }
        catch (error){
          console.error('An Error occured',error)
          setIsMonthlyLoading(false);

        }
      
      }

      async function recentOrders () {
        try {
          const response = await axios.request(recentOrderOptions)
          setRecentOrdersState(response.data)
          setIsLoading(false); 
        }
        catch(error){
          console.error('An Error occured', error)
          setIsLoading(false); 
        }
      }

      async function totalSales () {
        try {
          const response = await axios.request(getAllSalesOptions)
          setTotalSalesState(response.data[0].totalSales)
        }
        catch(error){
          console.error('An Error occured', error)
        }
      }
      async function totalOrders () {
        try {
          const response = await axios.request(completedOrderOptions)
          setTotalOrderState(response.data.completed)
        }
        catch(error){
          console.error('An Error occured', error)
        }
      }

      async function totalUsers () {
        try {
          const response = await axios.request(totalUsersOptions)
          setTotalUsersState(response.data.totalUsers)
        }
        catch(error){
          console.error('An Error occured', error)
        }
      }

      async function totalWeeklySales () {
        try {
          const response = await axios.request(totalWeeklyOptions)
          setTotalWeeklySalesState(response.data[0].totalWeeklySales)
          setTotalWeeklySalesNumber(response.data[0].week)

          setTotalWeekTwo(response.data[1].totalWeeklySales)
          setTotalWeekTwoNumber(response.data[1].week)

          setTotalWeekThree(response.data[2].totalWeeklySales)
          setTotalWeekThreeNumber(response.data[2].week)

          setTotalWeekFour(response.data[3].totalWeeklySales)
          setTotalWeekFourNumber(response.data[3].week)

          setTotalWeekFive(response.data[4].totalWeeklySales)
          setTotalWeekFiveNumber(response.data[4].week)


        }
        catch(error){
          console.error('An Error occured', error)
        }
      }

      async function totalDailySales () {
        try {
          const response = await axios.request(totalDailyOptions)
          setTotalDailySalesState(response.data[0].today)
        }
        catch(error){
          console.error('An Error occured', error)
        }
      }

      totalDailySales()
      totalWeeklySales()
      totalUsers()
      totalOrders()
      totalSales()
      recentOrders()
      monthlySales()
    }, [accessToken])


  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Monthly Sales"}
          subtitle={
            isMonthlyLoading ? loading : `$${totalMonthlySales[0].totalSales}`
          }
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Completed Orders"}
          subtitle={totalOrderState}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Sales"}
          subtitle={`$${totalSalesState}`}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Today Sales"}
          subtitle={`$${totalDailySalesState}`}
        />
        
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Weekly Sales"}
          subtitle={`$${totalWeeklySalesState}`}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total Users"}
          subtitle={totalUsersState}
        />
        
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent
          salesOne={totalWeeklySalesState} 
          salesOneNumber={totalWeeklySalesNumber} 
          salesTwo={totalWeekTwo}
          salesTwoNumber={totalWeekTwoNumber}
          salesThree={totalWeekThree}
          salesThreeNumber={totalWeekThreeNumber}

          salesFour={totalWeekFour}
          salesFourNumber={totalWeekFourNumber}

          salesFive={totalWeekFive}
          salesFiveNumber={totalWeekFiveNumber} 
        />
        
        {/* <WeeklyRevenue 
          salesOne={totalWeeklySalesState} 
          salesOneNumber={totalWeeklySalesNumber} 
          salesTwo={totalWeekTwo}
          salesTwoNumber={totalWeekTwoNumber}
          salesThree={totalWeekThree}
          salesThreeNumber={totalWeekThreeNumber}

          salesFour={totalWeekFour}
          salesFourNumber={totalWeekFourNumber}

          salesFive={totalWeekFive}
          salesFiveNumber={totalWeekFiveNumber}
        /> */}
        <div className="mt-5">
        {/* Check Table */}
       
        {
            isLoading ? ( <p>Loading data ...</p>) : 
            
            <CheckTable
            columnsData={columnsDataCheck}
            tableData={recentOrdersState}
          />
          }
          
    
      </div>
      </div>

      
    </div>
  );
};

export default Dashboard;
